<template>
  <hello-world />
</template>

<script>
  import HelloWorld from '../components/HelloWorld'

  export default {
    name: 'home-page',

    components: {
      HelloWorld,
    },
  }
</script>
