<template>
  <v-card>
    <v-toolbar dense>
      <v-toolbar-title>Collections</v-toolbar-title>
      <template v-slot:extension>
        <v-tabs v-model="main_tab" @change="changePath()">
          <v-tab
              v-for="collection in collections"
              :key="collection"
          >{{ collection }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-tabs-items v-model="main_tab">
      <v-tab-item>
        <TastesCollection/>
      </v-tab-item>
      <v-tab-item>
        <CountriesCollection/>
      </v-tab-item>
      <v-tab-item>
        <AgeGroupCollection/>
      </v-tab-item>
      <v-tab-item>
        <ClassificationTypeCollection/>
      </v-tab-item>
      <v-tab-item>
        <ActionsCollection/>
      </v-tab-item>
      <v-tab-item>
        <NutrientsCollection/>
      </v-tab-item>
      <v-tab-item>
        <IngredientPropertyCollection/>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import TastesCollection from "@/components/TastesCollection";
import CountriesCollection from "@/components/CountriesCollection";
import AgeGroupCollection from "@/components/AgeGroupCollection";
import ClassificationTypeCollection from "@/components/ClassificationTypeCollection";
import ActionsCollection from "@/components/ActionsCollection";
import NutrientsCollection from "@/components/NutrientsCollection";
import IngredientPropertyCollection from "@/components/IngredientPropertyCollection";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Collections",
  components: {TastesCollection, CountriesCollection, AgeGroupCollection, ClassificationTypeCollection,
  ActionsCollection, NutrientsCollection, IngredientPropertyCollection},
  data() {
    return {
      main_tab: 0,
      collections: ['tastes', 'countries', 'age group', 'classification type', 'actions', 'nutrients', 'ingredient property']

    }
  },
  computed:{
    param: function (){
      return this.collections[this.main_tab]
    }
  },
  methods:{
    changePath(){
      this.$router.push(`/collections/${this.param}`)
    }
  },
  beforeMount() {
    this.main_tab = this.collections.indexOf(this.$route.params.param)
  }
}
</script>

<style scoped>

</style>