<template>
  <v-container fluid>
    <div class="center">
      <NewsList/>
      <v-pagination
          @next="nextPage()"
          v-model="pageNxt"
          :length="pages"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import NewsList from "@/components/NewsList";
export default {
  name: "NewsPage",
  components: {NewsList},
  data(){
    return{
      pages: null,
      pageNxt: +this.$route.params.page,
    }
  },
  beforeRouteUpdate(to, from, next){
    this.page = +to.params.page
    next()
  },
  methods:{
    nextPage(){
      this.$router.push(`/ingredients/page=${this.pageNxt}`)
      axios.get('http://142.93.161.109:8001/api/admin/news',{
        params:{
          page: this.$route.params.page,
          page_size: 20
        }
      })
          .then(response =>{
            this.pages = response.data.total_pages
          })
    }
  },
  beforeCreate() {
    axios.get('http://142.93.161.109:8001/api/admin/news',{
      params:{
        page: this.$route.params.page,
        page_size: 20
      }
    })
        .then(response =>{
          this.pages = response.data.total_pages
        })
  }
}
</script>

<style scoped>

</style>