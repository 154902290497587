<template>
  <div>
  <v-tabs v-model="nutrients_tab" fixed-tabs @change="getNewDataTable(nutrients_tab)">
    <v-tab
        v-for="item in nutrients_items"
        :key="item"

    >
      {{ item }}
    </v-tab>
  </v-tabs>
  <v-data-table
      v-cloak
      :headers="headers"
      :items="nutrients_filtered"
      :loading="nutrients_unavailable"
      fixed-header
  >
    <template v-slot:top>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" class="del-canc" outlined text @click="closeDelete">Cancel</v-btn>
            <v-btn color="red darken-1" class="del-conf" outlined text @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div class="d-flex justify-end mx-5">
        <v-dialog
            v-model="dialog"
            max-width="500px"
            @click:outside="close()"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                class="mb-2 mt-2"
                v-bind="attrs"
                v-on="on"
            >
              New Item
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">New Action</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                      sm="6"
                      md="4"
                  >
                    <v-text-field
                        v-model="new_nutrients.title"
                        label="Title"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="4"
                  >
                    <v-select
                        :items="nutrients_items"
                        v-model="new_nutrients.nutrient_type"
                        label="Nutrient Type"
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <template v-slot:[`item.actions`]="{item}">
      <v-icon
          small
          class="mr-2"
          @click.prevent="editItem(item)"
      >mdi-pencil</v-icon>
      <v-icon
          small
          @click="deleteItem(item)"
      >mdi-delete</v-icon>
    </template>
  </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CountriesCollection",
  data(){
    return {
      nutrients_unavailable: true,
      nutrients_tab: 'proteins',
      nutrients_items: ['protein', 'fat', 'carbohydrate', 'mineral', 'vitamin', 'other'],
      dialog: false,
      new_nutrients: {},
      dialogDelete: false,
      items_per_page: 20,
      editing_item: false,
      deletingIndex: -1,
      nutrients_filtered : [],
      nutrients: [],
      headers: [
        { text: 'Title', value: 'title' },
        { text: 'Actions', value: 'actions', sortable: false}
      ]
    }
  },
  methods:{
    getNewDataTable(nutrients_tab){
      this.nutrients_unavailable = true
      this.nutrients_filtered = this.nutrients.filter(item => item.nutrient_type === this.nutrients_items[nutrients_tab])
      this.nutrients_unavailable = false
    },
    save(){

      let url = `http://142.93.161.109:8001/api/admin/collections/nutrients`
      if (this.editing_item){

        url = `http://142.93.161.109:8001/api/admin/collections/nutrients/${this.editing_item.id}`
        axios.patch(`${url}`, this.new_nutrients)
            .then(()=>{
              axios.get(`http://142.93.161.109:8001/api/admin/collections/nutrients`)
                  .then(response =>{
                    this.nutrients = response.data
                    this.nutrients_filtered = this.nutrients.filter(item => item.nutrient_type === this.nutrients_items[this.nutrients_tab])
                    this.editing_item = false
                    this.new_nutrients = {}
                    this.dialog = false
                  })
            })
      }
      else {
        axios.post(`${url}`,this.new_nutrients)
            .then(()=>{
              axios.get(`${url}`)
                  .then(response =>{
                    this.nutrients = response.data
                    this.nutrients_filtered = this.nutrients.filter(item => item.nutrient_type === this.nutrients_items[this.nutrients_tab])
                    this.new_nutrients = {}
                    this.dialog = false
                  })
            })
      }
      return
    },
    close(){
      this.dialog=false
      this.editing_item = false
      this.new_nutrients = ''
    },
    editItem(item){
      this.dialog=true
      this.editing_item = item
      console.log(this.editing_item.id)
      this.new_nutrients.title = item.title
      this.new_nutrients.nutrient_type = item.nutrient_type
    },
    deleteItem(item){
      this.dialogDelete = true
      this.editing_item = item
    },
    closeDelete(){
      this.dialogDelete = false
      this.editing_item = false
      this.deletingIndex = -1
    },
    deleteItemConfirm(){
      axios.delete(`http://142.93.161.109:8001/api/admin/collections/nutrients/${this.editing_item.id}`)
          .then(()=>{
            axios.get('http://142.93.161.109:8001/api/admin/collections/nutrients')
                .then(response =>{
                  this.nutrients = response.data
                  this.nutrients_filtered = this.nutrients.filter(item => item.nutrient_type === this.nutrients_items[this.nutrients_tab])
                })
          })
      this.dialogDelete = false
    }
  },
  beforeCreate() {
    axios.get('http://142.93.161.109:8001/api/admin/collections/nutrients')
        .then(response =>{
          this.nutrients = response.data
          this.nutrients_filtered = this.nutrients.filter(item => item.nutrient_type === 'protein')
          if (!this.nutrients_filtered.length){
            this.nutrients_unavailable = false
          }
        })
  }
}
</script>

<style scoped>
th{
  text-align: end !important;
}
</style>