<template>
  <v-app>
    <v-navigation-drawer app clipped>
      <v-list>
        <router-link to="/ingredients/page=1"
                     custom v-slot="{ href, navigate, isActive}"
                     style="text-decoration: none">
          <a :href="href" @click="navigate">
            <v-list-item
                link
                :class="{'activel': isActive}"
            >
              <v-list-item-icon>
                <v-icon>mdi-food-apple</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Ingredients
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </a>
        </router-link>
        <router-link to="/collections/tastes"
                     style="text-decoration: none"
                     custom v-slot="{ href, navigate, isActive}"
        >
          <a :href="href" @click="navigate">
            <v-list-item
                link
                :class="{'activel': isActive}"
            >
              <v-list-item-icon>
                <v-icon>mdi-book</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Collections</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </a>
        </router-link>
        <router-link to="/recipes/page=1"
                     style="text-decoration: none"
                     custom v-slot="{ href, navigate, isActive}"
        >
          <a :href="href" @click="navigate">
            <v-list-item
                link
                :class="{'activel': isActive}"
            >
              <v-list-item-icon>
                <v-icon>mdi-food-takeout-box</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Recepies</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </a>
        </router-link>
        <router-link to="/news/page=1"
                     style="text-decoration: none"
                     custom v-slot="{ href, navigate, isActive}"
        >
          <a :href="href" @click="navigate">
            <v-list-item
                link
                :class="{'activel': isActive}"
            >
              <v-list-item-icon>
                <v-icon>mdi-newspaper-variant-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>News</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </a>
        </router-link>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar class="primary" app clipped-left elevation="10">
      <p class="white--text text-h6">Ayulife Logo</p>
      <v-spacer></v-spacer>
      <v-btn class="rounded-xl primary" elevation="0">
        <v-icon>mdi-dots-horizontal</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({}),
};
</script>

<style scoped>
.activel {
  background-color: rgba(0, 0, 0, 0.2);
}
</style>
