<template>
  <v-container fluid>
    <RecipesList/>
    <div class="center">
      <v-pagination
          @next="nextPage()"
          v-model="page"
          :length="pages"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import RecipesList from "@/components/RecipesList";
import axios from "axios";

export default {
  name: "RecipesPage",
  components: {RecipesList},
  data(){
    return{
      pages: null,
      page: +this.$route.params.page,
    }
  },
  beforeRouteUpdate(to, from, next){
    this.page = +to.params.page
    next()
  },
  methods:{
    nextPage(){
      this.$router.push(`/ingredients/page=${this.page}`)
      axios.get('http://142.93.161.109:8001/api/admin/ingredients',{
        params:{
          page: +this.$route.params.page,
          page_size: 20
        }
      })
          .then(response =>{
            this.pages = response.data.total_pages
          })
    }
  },
  beforeCreate() {
    axios.get('http://142.93.161.109:8001/api/admin/ingredients',{
      params:{
        page: +this.$route.params.page,
        page_size: 20
      }
    })
        .then(response =>{
          this.pages = response.data.total_pages
        })
  }

}
</script>

<style scoped>

</style>