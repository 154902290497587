<template>
  <v-card>
    <v-toolbar class="elevation-0">
      <v-toolbar-title class="font-weight-bold">Creating A New</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-text-field
          label="Title"
          dense
          class="ml-10 d-inline-block"
          v-model="news.title"
          outlined
          style="width: 60%;"
      />
      <v-text-field
          label="Time To Read"
          dense
          outlined
          @input="updateTime"
          suffix="min"
          :rules="[rules[0]]"
          v-model="time_to_read"
          class="d-inline-block ml-15"
          style="width: 20%;"
      />
      <v-file-input
          label="Choose a picture"
          accept="image/*"
          id="image"
          ref="file_input"
          @change="imageInput()"
          @click:clear="news.image_id = null"
          style="width: 85%"
          class="mx-10"
      />
      <v-textarea
          label="Body"
          v-model='news.text'
          outlined
          rows="8"
          row-height="18"
          style="width: 85%"
          class="mx-10"
      />
      <v-text-field
          label="YouTube Link"
          dense
          class="ml-10 d-inline-block"
          v-model="news.youtube_link"
          outlined
          style="width: 85%;"
      />

      <v-alert
          border="right"
          colored-border
          type="error"
          elevation="2"
          class="ml-10"
          style="width: 85%;"
      >
        Be careful only three first chosen tags will be displayed in the mobile app
      </v-alert>
      <v-row class="ml-6">
        <v-subheader style="font-size: 16px">Season Tags:</v-subheader>
        <v-checkbox
            :input-value="checkboxTags"
            dense
            label="Summer"
            class="mx-5"
            value="Summer"
            @change="updateTags($event)"
        />
        <v-checkbox
            :input-value="checkboxTags"
            dense
            class="mx-5"
            label="Spring"
            value="Spring"
            @change="updateTags($event)"
        />
        <v-checkbox
            :input-value="checkboxTags"
            dense
            label="Autumn"
            class="mx-5"
            value="Autumn"
            @change="updateTags($event)"
        />
        <v-checkbox
            :input-value="checkboxTags"
            dense
            label="Winter"
            class="mx-5"
            value="Winter"
            @change="updateTags($event)"
        />
      </v-row>
      <v-row class="ml-6">
        <v-subheader style="font-size: 16px">Element Tags:</v-subheader>
        <v-checkbox
            :input-value="checkboxTags"
            dense
            label="Earth"
            class="mx-5"
            value="Earth"
            @change="updateTags($event)"
        />
        <v-checkbox
            :input-value="checkboxTags"
            dense
            class="mx-5"
            label="Ether"
            value="Ether"
            @change="updateTags($event)"
        />
        <v-checkbox
            :input-value="checkboxTags"
            dense
            label="Water"
            class="mx-5"
            value="Water"
            @change="updateTags($event)"
        />
        <v-checkbox
            :input-value="checkboxTags"
            dense
            label="Fire"
            class="mx-5"
            value="Fire"
            @change="updateTags($event)"
        />
        <v-checkbox
            :input-value="checkboxTags"
            dense
            label="Wind"
            class="mx-5"
            value="Wind"
            @change="updateTags($event)"
        />
      </v-row>


      <v-row class="ml-6">
        <v-subheader style="font-size: 16px">Dosha Tags:</v-subheader>
        <v-checkbox
            :input-value="checkboxTags"
            dense
            label="Vata"
            class="mx-5"
            value="Vata"
            @change="updateTags($event)"
        />
        <v-checkbox
            dense
            class="mx-5"
            label="Pitta"
            value="Pitta"
            @change="updateTags($event)"
        />
        <v-checkbox
            :input-value="checkboxTags"
            dense
            label="Kapha"
            class="mx-5"
            value="Kapha"
            @change="updateTags($event)"
        />
      </v-row>

      <v-row class="ml-6">
        <v-subheader style="font-size: 16px">Time of Day Tags:</v-subheader>
        <v-checkbox
            :input-value="checkboxTags"
            dense
            label="Sunrise"
            class="mx-5"
            value="Sunrise"
            @change="updateTags($event)"
        />
        <v-checkbox
            :input-value="checkboxTags"
            dense
            class="mx-5"
            label="Midnight"
            value="Midnight"
            @change="updateTags($event)"
        />
        <v-checkbox
            :input-value="checkboxTags"
            dense
            label="Midday"
            class="mx-5"
            value="Midday"
            @change="updateTags($event)"
        />
        <v-checkbox
            :input-value="checkboxTags"
            dense
            label="Sunset"
            class="mx-5"
            value="Sunset"
            @change="updateTags($event)"
        />
      </v-row>

      <v-row>
        <v-switch
            label="Recommended"
            v-model="news.is_recommended"
            dense
            class="ml-10 d-inline-block"
        />
        <v-spacer/>
        <v-radio-group
            dense
            class="d-inline-block"
            style="margin-right: 10%"
            v-model="news.category"
        >
          <v-row>
            <v-subheader style="font-size: 16px">Category:</v-subheader>
            <v-radio
                class="mx-5"
                label="Yoga"
                value="yoga"
            />
            <v-radio
                class="mx-5"
                label="Ayurveda"
                value="ayurveda"
            />
          </v-row>

        </v-radio-group>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex">
      <v-btn
          class="primary mx-auto mr-5"
          @click="sendNews"
          :disabled="!availableToSend"
      >Create A News
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateANew",
  props: {
    editingNews: {
      type: Object,
      default: ()=>{}
    },
    tags:  {
      type: Array,
      default: ()=>[]
    }
  },
  data() {
    return {
      rules: [ value => {
        const pattern = /^[1234567890.]*$/
        return pattern.test(value) || 'Incorrect input'
      }],
      time_to_read: (this.editingNews) ? this.editingNews.time_to_read.slice(0,this.editingNews.time_to_read.indexOf('min')-1): '',
      checkboxTags: (this.tags) ? this.tags : [],
      news: (this.editingNews) ? this.editingNews : {
        is_recommended: false,
        category: '',
        youtube_link: null,
        title: '',
        time_to_read: '',
        image_id: null,
        text: '',
        tags: []
      }
    }
  },
  methods: {
    updateTags(e){
      this.checkboxTags = e
    },
    updateTime() {
      this.news.time_to_read = this.time_to_read + ' min'
    },
    async sendNews() {
      let count = 0
      this.news.tags=[]
      for (let tag of this.checkboxTags) {
        if (count < 3){
          this.news.tags.push({name:tag, is_main: true})
          count++;
        } else {
          this.news.tags.push({name:tag, is_main: false})
        }
      }
      await axios.post('http://142.93.161.109:8001/api/admin/news', this.news)
      await this.$router.push('news/page=1')
    },
    async imageInput() {
      let image = document.querySelector("#image")
      let imageToSend = new FormData()
      imageToSend.append('upload_dir', 'news')
      imageToSend.append('image', image.files[0])
      const data = await axios.post('http://142.93.161.109:8001/api/admin/images', imageToSend)
      this.news.image_id = data.data.id
    }
  },
  computed:{
    availableToSend() {
      let available = true
      for (let key in this.news) {
        if (!(key === 'is_recommended' || key === 'youtube_link' || key === 'image_id')){
          available = available && Boolean(this.news[key])
        }
      }
      return (available && (Boolean(this.news.image_id) || Boolean(this.news.youtube_link)))
    }
  }
}
</script>

<style scoped>
.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 0px !important;
}

</style>
