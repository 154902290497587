<template>
  <div>
    <v-tabs v-model="ingredient_property_tab" fixed-tabs @change="getNewDataTable(ingredient_property_tab)">
      <v-tab
          v-for="item in ingredient_property_items"
          :key="item"

      >
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-data-table
        v-cloak
        :headers="headers"
        :items="ingredient_property_filtered"
        :loading="ingredient_property_unavailable"
        fixed-header
    >
      <template v-slot:top>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" class="del-canc" outlined text @click="closeDelete">Cancel</v-btn>
              <v-btn color="red darken-1" class="del-conf" outlined text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="d-flex justify-end mx-5">
          <v-dialog
              v-model="dialog"
              max-width="500px"
              @click:outside="close()"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  dark
                  class="mb-2 mt-2"
                  v-bind="attrs"
                  v-on="on"
              >
                New Item
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">New Ingredient Property</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                      <v-text-field
                          v-model="new_ingredient_property.title"
                          label="Title"
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                      <v-select
                          :items="ingredient_property_items"
                          v-model="new_ingredient_property.ingredient_property_type"
                          label="Ingredient Property"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{item}">
        <v-icon
            small
            class="mr-2"
            @click.prevent="editItem(item)"
        >mdi-pencil</v-icon>
        <v-icon
            small
            @click="deleteItem(item)"
        >mdi-delete</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CountriesCollection",
  data(){
    return {
      ingredient_property_unavailable: true,
      ingredient_property_tab: 'disease condition',
      ingredient_property_items: ['disease condition', 'dosha property', 'seasonal preference', 'physiology', 'physical activity', 'auraveda'],
      ingredient_property_items_back: ['disease_condition', 'dosha_property', 'seasonal_preference', 'physiology', 'physical_activity', 'auraveda'],
      dialog: false,
      new_ingredient_property: {},
      dialogDelete: false,
      items_per_page: 20,
      editing_item: false,
      deletingIndex: -1,
      ingredient_property_filtered : [],
      ingredient_property: [],
      headers: [
        { text: 'Title', value: 'title' },
        { text: 'Actions', value: 'actions', sortable: false}
      ]
    }
  },
  methods:{
    getNewDataTable(ingredient_property_tab){
      this.ingredient_property_unavailable = true
      this.ingredient_property_filtered = this.ingredient_property.filter(item => item.ingredient_property_type === this.ingredient_property_items_back[ingredient_property_tab])
      this.ingredient_property_unavailable = false
    },
    save(){
      this.new_ingredient_property.ingredient_property_type = this.ingredient_property_items_back[this.ingredient_property_items.indexOf(this.new_ingredient_property.ingredient_property_type)]
      let url = `http://142.93.161.109:8001/api/admin/collections/ingredient-properties`
      if (this.editing_item){

        url = `http://142.93.161.109:8001/api/admin/collections/ingredient-properties/${this.editing_item.id}`
        axios.patch(`${url}`, this.new_ingredient_property)
            .then(()=>{
              axios.get(`http://142.93.161.109:8001/api/admin/collections/ingredient-properties`)
                  .then(response =>{
                    this.ingredient_property = response.data
                    this.ingredient_property_filtered = this.ingredient_property.filter(item => item.ingredient_property_type === this.ingredient_property_items_back[this.ingredient_property_tab])
                    this.new_ingredient_property = {}
                    this.editing_item = false
                    this.dialog = false
                  })
            })
      }
      else {
        axios.post(`${url}`,this.new_ingredient_property)
            .then(()=>{
              axios.get(`${url}`)
                  .then(response =>{
                    this.ingredient_property = response.data
                    this.ingredient_property_filtered = this.ingredient_property.filter(item => item.ingredient_property_type === this.ingredient_property_items_back[this.ingredient_property_tab])
                    this.new_ingredient_property = {}
                    this.dialog = false
                  })
            })
      }
    },
    close(){
      this.dialog=false
      this.editing_item = false
      this.new_ingredient_property = {}
    },
    editItem(item){
      this.dialog=true
      this.editing_item = item
      this.new_ingredient_property.title = item.title
      this.new_ingredient_property.ingredient_property_type = this.ingredient_property_items[this.ingredient_property_items_back.indexOf(item.ingredient_property_type)]
    },
    deleteItem(item){
      this.dialogDelete = true
      this.editing_item = item
    },
    closeDelete(){
      this.dialogDelete = false
      this.editing_item = false
      this.deletingIndex = -1
    },
    deleteItemConfirm(){
      axios.delete(`http://142.93.161.109:8001/api/admin/collections/ingredient-properties/${this.editing_item.id}`)
          .then(()=>{
            axios.get('http://142.93.161.109:8001/api/admin/collections/ingredient-properties')
                .then(response =>{
                  this.ingredient_property = response.data
                  this.ingredient_property_filtered = this.ingredient_property.filter(item => item.ingredient_property_type === this.ingredient_property_items_back[this.ingredient_property_tab])
                })
          })
      this.dialogDelete = false
    }
  },
  beforeCreate() {
    axios.get('http://142.93.161.109:8001/api/admin/collections/ingredient-properties')
        .then(response =>{
          this.ingredient_property = response.data
          this.ingredient_property_filtered = this.ingredient_property.filter(item => item.ingredient_property_type === 'disease_condition')
          if (!this.ingredient_property_filtered.length){
            this.ingredient_property_unavailable = false
          }
        })
  }
}
</script>

<style scoped>
th{
  text-align: end !important;
}
</style>