var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-navigation-drawer',{attrs:{"app":"","clipped":""}},[_c('v-list',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/ingredients/page=1","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('v-list-item',{class:{'activel': isActive},attrs:{"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-food-apple")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Ingredients ")])],1)],1)],1)]}}])}),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/collections/tastes","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('v-list-item',{class:{'activel': isActive},attrs:{"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-book")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Collections")])],1)],1)],1)]}}])}),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/recipes/page=1","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('v-list-item',{class:{'activel': isActive},attrs:{"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-food-takeout-box")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Recepies")])],1)],1)],1)]}}])}),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/news/page=1","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('v-list-item',{class:{'activel': isActive},attrs:{"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-newspaper-variant-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("News")])],1)],1)],1)]}}])})],1)],1),_c('v-app-bar',{staticClass:"primary",attrs:{"app":"","clipped-left":"","elevation":"10"}},[_c('p',{staticClass:"white--text text-h6"},[_vm._v("Ayulife Logo")]),_c('v-spacer'),_c('v-btn',{staticClass:"rounded-xl primary",attrs:{"elevation":"0"}},[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)],1),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }