<template>
  <v-data-table
      :headers="headers"
      :loading="loading"
      hide-default-footer
      :items-per-page="items_per_page"
      :items="ingredients"
  >
    <template v-slot:top>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" class="del-canc" outlined text @click="closeDelete">Cancel</v-btn>
            <v-btn color="red darken-1" class="del-conf" outlined text @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div class="d-flex justify-end">
        <v-btn
            color="primary"
            dark
            @click="createNewIngredient()"
        >New Item
        </v-btn>
      </div>
    </template>
    <template v-slot:[`item.actions`]="{item}">
      <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
      >mdi-pencil</v-icon>
      <v-icon
          small
          @click="deleteItem(item)"
      >mdi-delete</v-icon>
    </template>
  </v-data-table>
</template>

<script>
import axios from "axios";
axios.defaults.headers['Access-Control-Allow-Origin']='*'

export default {
  name: "IngredientsList",
  data() {
    return {
      loading: true,
      defaultItem:{

      },
      editedItem:{

      },
      dialogDelete: false,
      items_per_page: 20,
      editingIndex: -1,
      deleting_item: null,
      ingredients: [],
      headers: [
        { text: 'Title', value: 'title' },
        { text: 'Energy', value: "energy"},
        { text: 'Description', value: 'description'},
        { text: 'Actions', value: 'actions', sortable: false}
      ]
    }
  },
  methods:{
    editItem(item){
      this.editingIndex = this.ingredients.indexOf(item)
      this.editedItem = Object.assign({},item)
      console.log(this.editedItem)
    },
    deleteItem(item){
      this.dialogDelete = true
      this.deleting_item = item
    },
    closeDelete(){
      this.dialogDelete = false
      this.deleting_item = null
    },
    deleteItemConfirm(){
      axios.delete(`http://142.93.161.109:8001/api/admin/ingredients/${this.deleting_item.id}`)
          .then(()=>{
            axios.get('http://142.93.161.109:8001/api/admin/ingredients', {
              params:{
                page: this.$route.params.page,
                page_size: 20
              }
            })
                .then(response =>{
                  this.ingredients = response.data.results
                  this.loading = false
                })
          })
      this.dialogDelete = false
    },
    createNewIngredient(){
      this.$router.push('/new_ingredient')
    }
  },
  beforeCreate() {
    axios.get('http://142.93.161.109:8001/api/admin/ingredients', {
      params:{
        page: this.$route.params.page,
        page_size: 20
      }
    })
    .then(response =>{
      this.ingredients = response.data.results
      this.loading = false
    })
  }
}
</script>

<style>
.del-conf.v-btn--outlined{
  border: thin solid darkred !important;
}
.del-canc.v-btn--outlined{
  border: thin solid #1976d2 !important;
}
</style>