<template>
  <v-data-table
      :headers="headers"
      hide-default-footer
      :items-per-page="items_per_page"
      :items="classification_types"
      :loading="!classification_types.length"
      fixed-header
  >
    <template v-slot:top>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" class="del-canc" outlined text @click="closeDelete">Cancel</v-btn>
            <v-btn color="red darken-1" class="del-conf" outlined text @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div class="d-flex justify-end mx-5">
        <v-dialog
            v-model="dialog"
            max-width="500px"
            @click:outside="close()"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
            >
              New Item
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">New Classification Type</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                      sm="6"
                      md="4"
                  >
                    <v-text-field
                        v-model="new_classification_types"
                        label="Title"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <template v-slot:[`item.actions`]="{item}">
      <v-icon
          small
          class="mr-2"
          @click.prevent="editItem(item)"
      >mdi-pencil</v-icon>
      <v-icon
          small
          @click="deleteItem(item)"
      >mdi-delete</v-icon>
    </template>
  </v-data-table>
</template>

<script>
import axios from "axios";

export default {
  name: "CountriesCollection",
  data(){
    return {
      dialog: false,
      new_classification_types: '',
      dialogDelete: false,
      items_per_page: 20,
      editing_item: false,
      deletingIndex: -1,
      classification_types: [],
      headers: [
        { text: 'Title', value: 'title' },
        { text: 'Actions', value: 'actions', sortable: false}
      ]
    }
  },
  methods:{
    save(){
      let url = `http://142.93.161.109:8001/api/admin/collections/classification-types`
      if (this.editing_item){

        url = `http://142.93.161.109:8001/api/admin/collections/classification-types/${this.editing_item.id}`
        axios.patch(`${url}`, {
          title: this.new_classification_types
        })
            .then(()=>{
              axios.get(`http://142.93.161.109:8001/api/admin/collections/classification-types`)
                  .then(response =>{
                    this.classification_types = response.data
                    this.editing_item = false
                    this.new_classification_types = ''
                    this.dialog = false
                  })
            })
      }
      else {
        axios.post(`${url}`, {
          title: this.new_classification_types
        })
            .then(()=>{
              axios.get(`${url}`)
                  .then(response =>{
                    this.classification_types = response.data
                    this.new_classification_types = ''
                    this.dialog = false
                  })
            })
      }
      return
    },
    close(){
      this.dialog=false
      this.editing_item = false
      this.new_classification_types = ''
    },
    editItem(item){
      this.dialog=true
      this.editing_item = item
      console.log(this.editing_item.id)
      this.new_classification_types = item.title
    },
    deleteItem(item){
      this.dialogDelete = true
      this.editing_item = item
    },
    closeDelete(){
      this.dialogDelete = false
      this.editing_item = false
      this.deletingIndex = -1
    },
    deleteItemConfirm(){
      axios.delete(`http://142.93.161.109:8001/api/admin/collections/classification-types/${this.editing_item.id}`)
          .then(()=>{
            axios.get('http://142.93.161.109:8001/api/admin/collections/classification-types')
                .then(response =>{
                  this.classification_types = response.data
                })
          })
      this.dialogDelete = false
    }
  },
  beforeCreate() {
    axios.get('http://142.93.161.109:8001/api/admin/collections/classification-types')
        .then(response =>{
          this.classification_types = response.data
        })
  }
}
</script>

<style scoped>
th{
  text-align: end !important;
}
</style>