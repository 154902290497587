<template>
  <v-card>
    <v-toolbar
        dense>
      <v-toolbar-title class="font-weight-bold">Creating New Ingredient</v-toolbar-title>
      <template v-slot:extension>
        <v-tabs v-model="tab" ref="tabs">
          <v-tab
              v-for="item in items"
              :key="item"
              :href="item"
              @click.prevent="goToNext(item)"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-tabs-items
        v-model="tab"
    >
      <v-tab-item value="general">
        <v-card flat>
          <v-card-text>
            <v-text-field
                class="d-inline-block ml-10"
                label="Title"
                outlined
                dense
                style="width: 40%"
                v-model="ingredient.title"
                required
                :rules="[rules[0]]"
            >
            </v-text-field>
            <v-text-field
                class="d-inline-block ml-15"
                label="Energy"
                outlined
                dense
                style="width: 40%"
                suffix="ccal"
                v-model="ingredient.energy"
                :rules="[rules[0], rules[1]]"
            >
            </v-text-field>
            <v-text-field
                v-model="ingredient.description"
                outlined
                dense
                label="Description"
                style="width: 85%;"
                class="mx-10"
                counter="80"
                :rules="[rules[0]]"
            >
            </v-text-field>
            <v-textarea
                v-model="ingredient.body"
                label="Body"
                outlined
                rows="8"
                row-height="18"
                style="width: 85%"
                class="mx-10"
                :rules="[rules[0]]"
            ></v-textarea>
          </v-card-text>
          <v-file-input
              label="Choose a picture"
              accept="image/*"
              id="image"
              ref="file_input"
              @change="imageInput()"
              @click:clear="removeImage()"
              style="width: 85%"
              class="mx-10"
          >
          </v-file-input>
          <v-switch
              v-model="ingredient.is_vegeterian"
              label="Vegetarian"
              class="mx-10 d-inline-block"
          >
          </v-switch>
          <v-switch
              v-model="ingredient.has_gluten"
              label="Has gluten"
              class="mx-10 d-inline-block"
          >
          </v-switch>
          <v-switch
              v-model="ingredient.has_purines"
              label="Has purines"
              class="mx-10 d-inline-block"
          >
          </v-switch>
          <v-checkbox
              label="Good for day"
              class="d-inline-block mx-10"
              @change="changeGoodForNightOrDay('day')"
          >
          </v-checkbox>
          <v-checkbox
              label="Good for night"
              class="d-inline-block mx-10"
              @change="changeGoodForNightOrDay('night')"
          >
          </v-checkbox>
          <v-text-field
              label="Used part"
              v-model="ingredient.used_part"
              class="mx-10"
              style="width: 85%"
              outlined
              dense
              :rules="[rules[0]]"
              required
          >

          </v-text-field>
          <v-card-actions

          >
            <v-btn
                v-if="fullfilled"
                @click="goToNext"
            >
              Next
            </v-btn>
            <v-btn
                v-else
                disabled
            >
              Next
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-tab-item>
      <v-tab-item value="nutrients">
        <v-card flat>
          <v-tabs v-model="nutrients_tab" fixed-tabs>
            <v-tab
                v-for="item in nutrients_items"
                :key="item"
            >
              {{ item }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="nutrients_tab">
            <v-tab-item
            >
              <v-card>
                <v-card-text>
                  <v-list>
                    <v-list-item
                        v-for="(nutrient,index) in nutrients.filter(item => item.nutrient_type === `protein`)"
                        :key="nutrient.title"
                    >
                      <v-list-item-title>{{ nutrient.title }}</v-list-item-title>
                      <v-list-item-subtitle>{{ nutrient.nutrient_type }}</v-list-item-subtitle>
                      <v-text-field
                          @change="addNewNutrient(nutrient, index)"
                          :rules="[rules[1]]"
                          v-model="nutrients_amount[nutrient.nutrient_type][index]"
                          style="width: 200px"
                          suffix="mg"
                      ></v-text-field>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item
            >
              <v-card>
                <v-card-text>
                  <v-list>
                    <v-list-item
                        v-for="(nutrient,index) in nutrients.filter(item => item.nutrient_type === `fat`)"
                        :key="nutrient.title"
                    >
                      <v-list-item-title>{{ nutrient.title }}</v-list-item-title>
                      <v-list-item-subtitle>{{ nutrient.nutrient_type }}</v-list-item-subtitle>
                      <v-text-field
                          @change="addNewNutrient(nutrient, index)"
                          v-model="nutrients_amount[nutrient.nutrient_type][index]"
                          style="width: 200px"
                          suffix="mg"
                      ></v-text-field>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item
            >
              <v-card>
                <v-card-text>
                  <v-list>
                    <v-list-item
                        v-for="(nutrient,index) in nutrients.filter(item => item.nutrient_type === `carbohydrate`)"
                        :key="nutrient.title"
                    >
                      <v-list-item-title>{{ nutrient.title }}</v-list-item-title>
                      <v-list-item-subtitle>{{ nutrient.nutrient_type }}</v-list-item-subtitle>
                      <v-text-field
                          @change="addNewNutrient(nutrient, index)"
                          v-model="nutrients_amount[nutrient.nutrient_type][index]"
                          style="width: 200px"
                          suffix="mg"
                      ></v-text-field>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item
            >
              <v-card>
                <v-card-text>
                  <v-list>
                    <v-list-item
                        v-for="(nutrient,index) in nutrients.filter(item => item.nutrient_type === `mineral`)"
                        :key="nutrient.title"
                    >
                      <v-list-item-title>{{ nutrient.title }}</v-list-item-title>
                      <v-list-item-subtitle>{{ nutrient.nutrient_type }}</v-list-item-subtitle>
                      <v-text-field
                          @change="addNewNutrient(nutrient, index)"
                          v-model="nutrients_amount[nutrient.nutrient_type][index]"
                          style="width: 200px"
                          suffix="mg"
                      ></v-text-field>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item
            >
              <v-card>
                <v-card-text>
                  <v-list>
                    <v-list-item
                        v-for="(nutrient,index) in nutrients.filter(item => item.nutrient_type === `vitamin`)"
                        :key="nutrient.title"
                    >
                      <v-list-item-title>{{ nutrient.title }}</v-list-item-title>
                      <v-list-item-subtitle>{{ nutrient.nutrient_type }}</v-list-item-subtitle>
                      <v-text-field
                          @change="addNewNutrient(nutrient, index)"
                          v-model="nutrients_amount[nutrient.nutrient_type][index]"
                          style="width: 200px"
                          suffix="mg"
                      ></v-text-field>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item
            >
              <v-card>
                <v-card-text>
                  <v-list>
                    <v-list-item
                        v-for="(nutrient,index) in nutrients.filter(item => item.nutrient_type === `other`)"
                        :key="nutrient.title"
                    >
                      <v-list-item-title>{{ nutrient.title }}</v-list-item-title>
                      <v-list-item-subtitle>{{ nutrient.nutrient_type }}</v-list-item-subtitle>
                      <v-text-field
                          @change="addNewNutrient(nutrient, index)"
                          v-model="nutrients_amount[nutrient.nutrient_type][index]"
                          style="width: 200px"
                          suffix="mg"
                      ></v-text-field>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          <v-card-actions>
            <v-btn
                v-if="fullfilled"
                @click="goToNext"
            >
              Next
            </v-btn>
            <v-btn
                v-else
                disabled
            >
              Next
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item value="good for">
        <v-card flat>
          <v-tabs
              v-model="ingredient_property_tab"
              fixed tab
          >
            <v-tab v-for="item in ingredient_property_items"
                   :key="item"
            >{{ item }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="ingredient_property_tab">
            <v-tab-item>
              <v-card-text
                  class="d-inline-block"
                  style="overflow: auto; height: 15rem"
              >
                <v-checkbox
                    v-for="item in ingredient_property.filter(item => item.ingredient_property_type === 'disease_condition')"
                    :key="item.title"
                    :label="item.title"
                    class="d-inline-block"
                    style="width: 200px"
                    @click="updateProperty(item, 'good_for')"
                >
                </v-checkbox>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text
                  class="d-inline-block"
                  style="overflow: auto; height: 15rem"
              >
                <v-checkbox
                    v-for="item in ingredient_property.filter(item => item.ingredient_property_type === 'dosha_property')"
                    :key="item.title"
                    :label="item.title"
                    class="d-inline-block"
                    style="width: 200px"
                    @click="updateProperty(item, 'good_for')"
                >
                </v-checkbox>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text
                  class="d-inline-block"
                  style="overflow: auto; height: 15rem"
              >
                <v-checkbox
                    v-for="item in ingredient_property.filter(item => item.ingredient_property_type === 'seasonal_preference')"
                    :key="item.title"
                    :label="item.title"
                    class="d-inline-block"
                    style="width: 200px"
                    @click="updateProperty(item, 'good_for')"
                >
                </v-checkbox>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text
                  class="d-inline-block"
                  style="overflow: auto; height: 15rem"
              >
                <v-checkbox
                    v-for="item in ingredient_property.filter(item => item.ingredient_property_type === 'physiology')"
                    :key="item.title"
                    :label="item.title"
                    class="d-inline-block"
                    style="width: 200px"
                    @click="updateProperty(item, 'good_for')"
                >
                </v-checkbox>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text
                  class="d-inline-block"
                  style="overflow: auto; height: 15rem"
              >
                <v-checkbox
                    v-for="item in ingredient_property.filter(item => item.ingredient_property_type === 'physical_activity')"
                    :key="item.title"
                    :label="item.title"
                    class="d-inline-block"
                    style="width: 200px"
                    @click="updateProperty(item, 'good_for')"
                >
                </v-checkbox>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text
                  class="d-inline-block"
                  style="overflow: auto; height: 15rem"
              >
                <v-checkbox
                    v-for="item in ingredient_property.filter(item => item.ingredient_property_type === 'auraveda')"
                    :key="item.title"
                    :label="item.title"
                    class="d-inline-block"
                    style="width: 200px"
                    @click="updateProperty(item, 'good_for')"
                >
                </v-checkbox>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
          <v-card-actions

          >
            <v-btn
                v-if="fullfilled"
                @click="goToNext"
            >
              Next
            </v-btn>
            <v-btn
                v-else
                disabled
            >
              Next
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item value="bad for">
        <v-card flat>
          <v-tabs
              v-model="ingredient_property_tab"
              fixed tab
          >
            <v-tab v-for="item in ingredient_property_items"
                   :key="item"
            >{{ item }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="ingredient_property_tab">
            <v-tab-item>
              <v-card-text
                  class="d-inline-block"
                  style="overflow: auto; height: 15rem"
              >
                <v-checkbox
                    v-for="item in ingredient_property.filter(item => item.ingredient_property_type === 'disease_condition')"
                    :key="item.title"
                    :label="item.title"
                    class="d-inline-block"
                    style="width: 200px"
                    @click="updateProperty(item, 'bad_for')"
                >
                </v-checkbox>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text
                  class="d-inline-block"
                  style="overflow: auto; height: 15rem"
              >
                <v-checkbox
                    v-for="item in ingredient_property.filter(item => item.ingredient_property_type === 'dosha_property')"
                    :key="item.title"
                    :label="item.title"
                    class="d-inline-block"
                    style="width: 200px"
                    @click="updateProperty(item, 'bad_for')"
                >
                </v-checkbox>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text
                  class="d-inline-block"
                  style="overflow: auto; height: 15rem"
              >
                <v-checkbox
                    v-for="item in ingredient_property.filter(item => item.ingredient_property_type === 'seasonal_preference')"
                    :key="item.title"
                    :label="item.title"
                    class="d-inline-block"
                    style="width: 200px"
                    @click="updateProperty(item, 'bad_for')"
                >
                </v-checkbox>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text
                  class="d-inline-block"
                  style="overflow: auto; height: 15rem"
              >
                <v-checkbox
                    v-for="item in ingredient_property.filter(item => item.ingredient_property_type === 'physiology')"
                    :key="item.title"
                    :label="item.title"
                    class="d-inline-block"
                    style="width: 200px"
                    @click="updateProperty(item, 'bad_for')"
                >
                </v-checkbox>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text
                  class="d-inline-block"
                  style="overflow: auto; height: 15rem"
              >
                <v-checkbox
                    v-for="item in ingredient_property.filter(item => item.ingredient_property_type === 'physical_activity')"
                    :key="item.title"
                    :label="item.title"
                    class="d-inline-block"
                    style="width: 200px"
                    @click="updateProperty(item, 'bad_for')"
                >
                </v-checkbox>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text
                  class="d-inline-block"
                  style="overflow: auto; height: 15rem"
              >
                <v-checkbox
                    v-for="item in ingredient_property.filter(item => item.ingredient_property_type === 'auraveda')"
                    :key="item.title"
                    :label="item.title"
                    class="d-inline-block"
                    style="width: 200px"
                    @click="updateProperty(item, 'bad_for')"
                >
                </v-checkbox>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
          <v-card-actions
          >
            <v-btn
                v-if="fullfilled"
                @click="goToNext"
            >
              Next
            </v-btn>
            <v-btn
                v-else
                disabled
            >
              Next
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item value="taste">
        <v-card flat>
          <v-card-text>
            <v-checkbox
                v-for="item in tastes"
                :key="item.title"
                :label="item.title"
                class="d-inline-block"
                style="width: 200px"
                @click="updateProperty(item, 'taste')"
            >
            </v-checkbox>
          </v-card-text>
          <v-card-actions
          >
            <v-btn
                v-if="fullfilled"
                @click="goToNext"
            >
              Next
            </v-btn>
            <v-btn
                v-else
                disabled
            >
              Next
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item value="actions">
        <v-card flat>
          <v-card-text>
            <v-checkbox
                v-for="item in actions"
                :key="item.title"
                :label="item.title"
                class="d-inline-block"
                style="width: 200px"
                @click="addAction(item)"
            >
            </v-checkbox>
          </v-card-text>
          <v-card-actions
          >
            <v-btn
                v-if="fullfilled"
                @click="goToNext"
            >
              Next
            </v-btn>
            <v-btn
                v-else
                disabled
            >
              Next
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item value="regional common food">
        <v-card flat>
          <v-card-text>
            <v-checkbox
                v-for="item in regional_common_food"
                :key="item.title"
                :label="item.title"
                class="d-inline-block"
                style="width: 200px"
                @click="addCountry(item)"
            >
            </v-checkbox>
          </v-card-text>
          <v-card-actions
          >
            <v-btn
                v-if="fullfilled"
                @click="goToNext"
            >
              Next
            </v-btn>
            <v-btn
                v-else
                disabled
            >
              Next
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item value="age group">
        <v-card flat>
          <v-card-text>
              <v-checkbox
                  v-for="age_group in age_groups"
                  :key="age_group.diapazone"
                  :label="age_group.diapazone"
                  :value="age_group"
                  class="d-inline-block"
                  style="width: 200px"
                  @change="addAgeGroup(age_group)"
              ></v-checkbox>
          </v-card-text>
          <v-card-actions
          >
            <v-btn
                v-if="fullfilled"
                @click="goToNext"
            >
              Next
            </v-btn>
            <v-btn
                v-else
                disabled
            >
              Next
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item value="classification type">
        <v-card flat>
          <v-card-text>
            <v-radio-group>
              <v-radio
                  v-for="type in classification_type"
                  :key="type.title"
                  :label="type.title"
                  :value="type.title"
                  @change="changeType(type)"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
          <v-card-actions
          >
            <v-btn
                v-if="ready_to_send"
                @click="sendNewIngredient"
            >
              Save Ingredient
            </v-btn>
            <v-btn
                v-else
                disabled
            >
              Save Ingredient
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateIngredient",
  data() {
    return {
      age_groups: [],
      ingredient_property_tab: 'disease condition',
      ingredient_property_items: ['disease condition', 'dosha property', 'seasonal preference', 'physiology', 'physical activity', 'auraveda'],
      nutrients_tab: 'proteins',
      nutrients_items: ['proteins', 'fats', 'carbohydrates', 'minerals', 'vitamins', 'other'],
      actions: [],
      regional_common_food: [],
      classification_type: [],
      tastes: [],
      current_tab: 0,
      rules: [v => v.length > 0, value => {
        const pattern = /^[1234567890.]*$/
        return pattern.test(value) || 'Incorrect input'
      }],
      nutrients: [],
      nutrients_amount: {
        protein: [],
        fat: [],
        carbohydrate: [],
        mineral: [],
        vitamin: [],
        other: []
      },
      ingredient_property: [],
      tab: 'general',
      items: ['general', 'nutrients', 'good for', 'bad for', 'taste', 'actions', 'regional common food', 'age group', 'classification type'],
      ingredient: {
        used_part: '',
        good_for_night: false,
        good_for_day: false,
        is_vegeterian: false,
        has_purines: false,
        has_gluten: false,
        title: '',
        energy: '',
        description: '',
        body: '',
        nutrients: [],
        good_for: [],
        bad_for: [],
        taste: [],
        actions: [],
        regional_common_food: [],
        age_group: [],
        classification_type: []
      },
      ingredient_done: new FormData(),
      rule: {}
    }
  },
  methods: {
    numberValidation(nutrient, index) {
      let input = this.nutrients_amount[nutrient.nutrient_type][index]
      if (!(input.charCodeAt(input.length - 1) < 58 && input.charCodeAt(input.length - 1) > 47 || input.charCodeAt(input.length - 1) === 46)) {
        return this.nutrients_amount[nutrient.nutrient_type][index] = input.slice(0, input.length - 1)
      }
    },
    changeGoodForNightOrDay(item) {
      let key = 'good_for_' + item
      this.ingredient[key] = !this.ingredient[key]
    },
    removeImage(){
      this.ingredient_done.delete('image')
    },
    imageInput() {
      let image = document.querySelector("#image")
      if (this.ingredient_done.has('image')) {
        this.ingredient_done.set('image', image.files[0])
      } else {
        this.ingredient_done.append('image', image.files[0])
      }
    },
    sendNewIngredient() {
      for (let i in this.ingredient) {
        if (Array.isArray(this.ingredient[i])) {
          if (i === 'nutrients') {
            for (let j in this.ingredient[i]) {
              this.ingredient_done.append(`nutrients[${j}]nutrient_id`, this.ingredient[i][j].nutrient_id)
              this.ingredient_done.append(`nutrients[${j}]unit`, this.ingredient[i][j].unit)
              this.ingredient_done.append(`nutrients[${j}]amount`, this.ingredient[i][j].amount)
            }
          }
          if (i === 'good_for') {
            for (let j in this.ingredient[i]) {
              this.ingredient_done.append(`good_for[${j}]ingredient_property_id`, this.ingredient[i][j].ingredient_property_id)
            }
          }
          if (i === 'bad_for') {
            for (let j in this.ingredient[i]) {
              this.ingredient_done.append(`bad_for[${j}]ingredient_property_id`, this.ingredient[i][j].ingredient_property_id)
            }
          }
          if (i === 'classification_type') {
            for (let j in this.ingredient[i]) {
              this.ingredient_done.append(`classification_type[classification_type_id]`, this.ingredient[i][j].classification_type_id)
            }
          }
          if (i === 'taste') {
            for (let j in this.ingredient[i]) {
              this.ingredient_done.append(`taste[taste_id]`, this.ingredient[i][j].taste_id)
            }
          }
          if (i === 'actions') {
            for (let j in this.ingredient[i]) {
              this.ingredient_done.append(`actions[${j}]action_id`, this.ingredient[i][j].action_id)
            }
          }
          if (i === 'age_group') {
            for (let j in this.ingredient[i]) {
              this.ingredient_done.append(`age_group[age_group_id]`, this.ingredient[i][j].age_group_id)
            }
          }
          if (i === 'regional_common_food') {
            for (let j in this.ingredient[i]) {
              this.ingredient_done.append(`regional_common_food[country_id]`, this.ingredient[i][j].country_id)
            }
          }
        } else {
          this.ingredient_done.append(`${i}`, this.ingredient[i])
        }
      }
      axios.post('http://142.93.161.109:8001/api/admin/ingredients', this.ingredient_done)
          .then(() => {
            this.$router.push('/ingredients/page=1')
          })
    },
    addAgeGroup(age_group) {
      this.ingredient.age_group.push({
        'age_group_id': age_group.id
      })
    },
    changeType(type) {
      this.ingredient.classification_type.push({
        'classification_type_id': type.id
      })
    },
    addAction(action) {
      this.ingredient.actions.push({
        'action_id': action.id
      })
    },
    addCountry(country) {
      this.ingredient.regional_common_food.push({
        'country_id': country.id
      })
    },
    goToNext(item) {
      if (this.items.includes(item)) {
        this.tab = item
        this.current_tab = this.items.indexOf(item)
      } else {
        if (this.items.indexOf(this.tab) !== this.items.length - 1) {
          this.tab = this.items[this.items.indexOf(this.tab) + 1]
          this.current_tab++
        }
      }
    },
    addNewNutrient(nutrient, index) {
      this.ingredient.nutrients.push({
        'unit': 'mg',
        'nutrient_id': nutrient.id,
        'amount': +this.nutrients_amount[nutrient.nutrient_type][index]
      })
    },
    updateProperty(item, label) {
      let action = 'add'
      let index = null
      for (let i in this.ingredient[label]) {
        if (item.title === this.ingredient[label][i].title) {
          action = 'remove'
          index = this.ingredient[label].indexOf(i)
        }
      }
      if (action === 'add') {
        if (label !== 'taste') {
          this.ingredient[label].push(
              {
                'ingredient_property_id': item.id
              }
          )
        } else {
          this.ingredient[label].push({
            'taste_id': item.id
          })
        }
      } else {
        this.ingredient[label].splice(index, 1)
      }
    }
  },
  computed: {
    fullfilled: function () {
      if (this.current_tab === 0) {
        return this.ingredient.title && this.ingredient.body && this.ingredient.energy && this.ingredient.description &&
            this.ingredient.used_part && this.ingredient_done.has('image')
      } else if (this.current_tab === 1) {
        return this.ingredient.nutrients.length
      } else if (this.current_tab === 2) {
        return this.ingredient.good_for.length
      } else if (this.current_tab === 3) {
        return this.ingredient.bad_for.length
      } else if (this.current_tab === 4) {
        return this.ingredient.taste.length
      } else if (this.current_tab === 5) {
        return this.ingredient.actions.length
      } else if (this.current_tab === 6) {
        return this.ingredient.regional_common_food.length
      } else if (this.current_tab === 7) {
        return this.ingredient.age_group.length
      } else {
        return true
      }
    },
    ready_to_send: function () {
      for (let i in this.ingredient) {
        if (!(i === 'classification_type' || i === 'age_group')) {
          if (this.ingredient[i].length === 0) {
            return false
          }
        } else {
          if (Object.keys(this.ingredient[i]).length === 0) {
            return false
          }
        }
      }
      return true
    }
  },
  beforeCreate() {
    axios.get('http://142.93.161.109:8001/api/admin/collections/nutrients')
        .then(response => {
              this.nutrients = response.data
              axios.get('http://142.93.161.109:8001/api/admin/collections/ingredient-properties')
                  .then(response => {
                    this.ingredient_property = response.data
                    axios.get('http://142.93.161.109:8001/api/admin/collections/tastes')
                        .then(response => {
                          this.tastes = response.data
                        })
                  })
            }
        )
    axios.get('http://142.93.161.109:8001/api/admin/collections/classification-types')
        .then(response => {
          this.classification_type = response.data
          axios.get('http://142.93.161.109:8001/api/admin/collections/actions')
              .then(response => {
                this.actions = response.data
                axios.get('http://142.93.161.109:8001/api/admin/collections/countries')
                    .then(response => {
                      this.regional_common_food = response.data
                      axios.get('http://142.93.161.109:8001/api/admin/collections/age-groups')
                          .then(response => {
                            this.age_groups = response.data
                          })
                    })
              })
        })
  }
}
</script>

<style scoped>

</style>
