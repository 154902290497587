<template>
  <v-card>
    <v-toolbar
        dense
    >
      <v-toolbar-title class="font-weight-bold">Create New Recipe</v-toolbar-title>
      <template v-slot:extension>
        <v-tabs v-model="tab" ref="tabs">
          <v-tab
              v-for="item in items"
              :key="item"
              :href="item"
              @click.prevent="goToNext(item)"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-tabs-items v-model="tab">
      <v-tab-item value="general">
        <v-card flat>
          <v-text-field
              label="Title"
              v-model="recipe.title"
              outlined
              dense
              style="width: 60%"
              class="mt-4 ml-10 d-inline-block"
              required
              :rules="[rules.text]"
          />
          <v-select
              label="Type of recipe"
              @change="addProperty($event, 'type_of_dish')"
              multiple
              :items="type_of_dish"
              item-text="title"
              style="width: 30%; max-width: 300px;"
              class="ml-10 d-inline-block"
              dense
              outlined
              single-line
          />
          <v-text-field
              label="Description"
              v-model="recipe.description"
              counter="80"
              dense
              outlined
              style="width: 60%"
              class="mx-10 d-inline-block"
              :rules="[rules.text]"
          />

          <v-text-field
              label="Cooking Time"
              v-model="recipe.cooking_time"
              style="width: 15%; max-width: 200px"
              class="d-inline-block"
              dense
              outlined
              single-line
              suffix="min"
          />
          <v-text-field
              label="Nutritional facts"
              v-model="recipe.nutrional_facts"
              counter="80"
              dense
              outlined
              style="width: 60%"
              class="mx-10 d-inline-block"
              :rules="[rules.text]"
          />
          <v-text-field
              label="Preparation Time"
              v-model="recipe.preparation_time"
              style="width: 15%; max-width: 200px"
              class="d-inline-block"
              dense
              outlined
              single-line
              suffix="min"
          />
          <v-file-input
              label="Choose a picture"
              accept="image/*"
              id="image"
              ref="file_input"
              @click:clear="removeImage()"
              @change="imageInput()"
              style="width: 85%"
              class="mx-10"
          />
          <v-select
              label="Meal Time"
              @change="addProperty($event, 'meal_timing')"
              :items="meal_timing"
              item-text="title"
              multiple
              style="width: 25%;"
              class="ml-10 d-inline-block"
              small-chips
              outlined
          />
          <v-select
              label="Seasons"
              @change="addProperty($event, 'seasons')"
              :items="seasons"
              item-text="title"
              multiple
              style="width: 25%;"
              class="ml-10 d-inline-block"
              small-chips
              outlined
          />
          <br/>
          <v-select
              label="Cuisine style"
              @change="addProperty($event, 'cuisine_style')"
              :items="cuisine_style"
              item-text="title"
              multiple
              style="width: 25%;"
              class="ml-10 d-inline-block"
              small-chips
              outlined
          />
          <v-select
              label="Cuisine region"
              @change="addProperty($event, 'regional_cuisine')"
              :items="regional_cuisine"
              item-text="title"
              multiple
              style="width: 25%;"
              class="ml-10 d-inline-block"
              small-chips
              outlined
          />
          <v-select
              label="Ayurveda"
              @change="addProperty($event, 'ayurveda')"
              :items="ayurveda"
              item-text="title"
              multiple
              style="width: 25%;"
              class="ml-10 d-inline-block"
              small-chips
              outlined
          />
        </v-card>
      </v-tab-item>

      <!--      Ingredients-->

      <v-tab-item value="ingredients">
        <v-dialog v-model="dialogAddIngredient"
                  max-width="500px"
                  persistent
        >
          <v-card
              class="d-inline-block"
          >
            <v-card-title class="text-h5">{{ addTitle }}</v-card-title>
            <v-card-text>
              <v-checkbox
                  class="d-inline-block mx-5"
                  v-for="ingredient in ingredients"
                  :key="ingredient.id"
                  :label="ingredient.title"
                  @change="addIngredient(ingredient)"
              />
            </v-card-text>
            <v-card-actions>
              <v-btn class="primary text-center"
                     @click="saveIngredient">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-row>
          <v-col>
            <v-card class="elevation-0 text-center">
              <v-card-title class="d-inline-block">Add key Ingredient</v-card-title>
              <v-btn icon
                     rounded
                     color="primary"
                     @click="showDialog('key')"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-card-text>
                <div v-for="ingredient in recipe.ingredients.filter(item => item.type === 'key')"
                     :key="ingredient.ingredient"
                >
                  <v-row>
                    <v-col cols="3">
                      <v-subheader
                          class="d-flex justify-center align-center font-weight-bold pb-4"
                          style="font-size: 1.2rem"
                      >
                        {{ getTitle(ingredient) }}
                      </v-subheader>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                          v-model="recipe.ingredients[recipe.ingredients.indexOf(ingredient)].quantity"
                          dense
                          class="d-inline-block"
                          style="width: 60%;"
                          label="Amount"
                          hide-details
                      />
                      <v-select
                          :items="['gr', 'ml']"
                          v-model="recipe.ingredients[recipe.ingredients.indexOf(ingredient)].unit"
                          class="d-inline-block ml-1"
                          dense
                          hide-details
                          outlined
                          style="width: 33%;"
                      />
                    </v-col>
                    <v-col cols="1">
                      <v-badge
                          icon="mdi-close"
                          overlap
                          color="error"
                          transition="slide-x-transition"
                          @click.native="deleteIngredient(ingredient.ingredient)"
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-divider class="fill-height" vertical/>
          <v-col>
            <v-card class="elevation-0 text-center">
              <v-card-title class="d-inline-block">Add secondary Ingredient</v-card-title>
              <v-btn icon
                     rounded
                     color="primary"
                     @click="showDialog('secondary')"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-card-text>
                <div v-for="ingredient in recipe.ingredients.filter(item => item.type === 'secondary')"
                     :key="ingredient.ingredient"
                >
                  <v-row>
                    <v-col cols="3">
                      <v-row class="mt-1 d-flex justify-center font-weight-bold" style="font-size: 1.2rem">
                        <span class="d-block">{{ getTitle(ingredient) }}</span>
                      </v-row>
                      <v-row class="mb-2 d-flex justify-center">
                        <span class="d-block" style="font-size: 0.9rem">{{ getSubtitle(ingredient) }}</span>
                      </v-row>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                          v-model="recipe.ingredients[recipe.ingredients.indexOf(ingredient)].quantity"
                          dense
                          class="d-inline-block"
                          style="width: 60%;"
                          label="Amount"
                          hide-details
                      />
                      <v-select
                          :items="['gr', 'ml']"
                          v-model="recipe.ingredients[recipe.ingredients.indexOf(ingredient)].unit"
                          class="d-inline-block ml-1"
                          dense
                          hide-details
                          outlined
                          style="width: 33%;"
                      />
                    </v-col>
                    <v-col cols="1">
                      <v-badge
                          icon="mdi-close"
                          overlap
                          color="error"
                          transition="slide-x-transition"
                          @click.prevent.native="deleteIngredient(ingredient.ingredient)"
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-divider class="fill-height" vertical/>

          <v-col>
            <v-card class="elevation-0 text-center">
              <v-card-title class="d-inline-block">Add tertiary Ingredient</v-card-title>
              <v-btn icon
                     rounded
                     color="primary"
                     @click="showDialog('tertiary')"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-card-text>
                <div
                    v-for="ingredient in recipe.ingredients.filter(item => item.type === 'tertiary')"
                    :key="ingredient.ingredient"
                >
                  <v-row>
                    <v-col cols="3">
                      <v-subheader
                          class="d-flex justify-center align-center font-weight-bold"
                          style="font-size: 1.2rem"
                      >
                        {{ getTitle(ingredient) }}
                      </v-subheader>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                          v-model="recipe.ingredients[recipe.ingredients.indexOf(ingredient)].quantity"
                          dense
                          type="number"
                          class="d-inline-block"
                          style="width: 60%;"
                          label="Amount"
                          hide-details
                      />
                      <v-select
                          :items="['gr', 'ml']"
                          v-model="recipe.ingredients[recipe.ingredients.indexOf(ingredient)].unit"
                          class="d-inline-block ml-1"
                          dense
                          hide-details
                          outlined
                          style="width: 33%;"
                      />
                    </v-col>
                    <v-col cols="1">
                      <v-badge
                          icon="mdi-close"
                          overlap
                          color="error"
                          transition="slide-x-transition"
                          @click.prevent.native="deleteIngredient(ingredient.ingredient)"/>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>

      <!--   Steps   -->

      <v-tab-item value="steps">
        <v-card>
          <v-card-text>
            <div
                id="steps"
                v-for="item in stemsAmount"
                :key="item"
            >
              <v-row>
                <v-col cols="1" class="text-center d-flex flex-column align-center justify-center">
                  <div style="border: 1px solid cornflowerblue; width: 36px; height: 36px;"
                       class="rounded-circle d-flex flex-column align-center justify-center primary--text">
                    <span class="font-weight-bold" style="font-size: 16px">{{ item }}</span>
                  </div>
                </v-col>
                <v-col cols="9">
                  <v-textarea
                      :label="'Enter the description of step ' + item +' here'"
                      rows="5"
                      outlined
                      hide-details
                      @input="pushStep($event, item)"
                  >
                  </v-textarea>
                </v-col>
                <v-col class="text-center d-flex flex-column align-center justify-center">
                  <v-btn
                      icon
                      rounded
                      outlined
                      color="primary"
                      @click="addStep()"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>


      <!--      Good For-->


      <v-tab-item value="good for">
        <v-card flat>
          <v-tabs
              v-model="recipePropertyTab"
              fixed tab
          >
            <v-tab v-for="item in recipePropertyItems"
                   :key="item"
            >{{ item }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="recipePropertyTab">
            <v-tab-item>
              <v-card-text
                  class="d-inline-block"
                  style="overflow: auto; height: 15rem"
              >
                <v-checkbox
                    v-for="item in diseas"
                    :key="item.title"
                    :label="item.title"
                    class="d-inline-block"
                    style="width: 200px"
                    @click="updateProperty(item, 'good_for_disease')"
                >
                </v-checkbox>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text
                  class="d-inline-block"
                  style="overflow: auto; height: 15rem"
              >
                <v-checkbox
                    v-for="item in doshaType"
                    :key="item.title"
                    :label="item.title"
                    class="d-inline-block"
                    style="width: 200px"
                    @click="updateProperty(item, 'good_for_dosha_type')"
                >
                </v-checkbox>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text
                  class="d-inline-block"
                  style="overflow: auto; height: 15rem"
              >
                <v-checkbox
                    v-for="item in agni"
                    :key="item.title"
                    :label="item.title"
                    class="d-inline-block"
                    style="width: 200px"
                    @click="updateProperty(item, 'good_for_agni')"
                >
                </v-checkbox>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text
                  class="d-inline-block"
                  style="overflow: auto; height: 15rem"
              >
                <v-checkbox
                    v-for="item in physiology"
                    :key="item.title"
                    :label="item.title"
                    class="d-inline-block"
                    style="width: 200px"
                    @click="updateProperty(item, 'good_for_physiology')"
                >
                </v-checkbox>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text
                  class="d-inline-block"
                  style="overflow: auto; height: 15rem"
              >
                <v-checkbox
                    v-for="item in physicalActivity"
                    :key="item.title"
                    :label="item.title"
                    class="d-inline-block"
                    style="width: 200px"
                    @click="updateProperty(item, 'good_for_physical_activity')"
                >
                </v-checkbox>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text
                  class="d-inline-block"
                  style="overflow: auto; height: 15rem"
              >
                <v-checkbox
                    v-for="item in mindGunas"
                    :key="item.title"
                    :label="item.title"
                    class="d-inline-block"
                    style="width: 200px"
                    @click="updateProperty(item, 'good_for_mind_gunas')"
                >
                </v-checkbox>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-tab-item>

      <!--Bad For-->


      <v-tab-item value="bad for">
        <v-card flat>
          <v-tabs
              v-model="recipePropertyTab"
              fixed tab
          >
            <v-tab v-for="item in recipePropertyItems"
                   :key="item"
            >{{ item }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="recipePropertyTab">
            <v-tab-item>
              <v-card-text
                  class="d-inline-block"
                  style="overflow: auto; height: 15rem"
              >
                <v-checkbox
                    v-for="item in diseas"
                    :key="item.title"
                    :label="item.title"
                    class="d-inline-block"
                    style="width: 200px"
                    @click="updateProperty(item, 'bad_for_disease')"
                >
                </v-checkbox>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text
                  class="d-inline-block"
                  style="overflow: auto; height: 15rem"
              >
                <v-checkbox
                    v-for="item in doshaType"
                    :key="item.title"
                    :label="item.title"
                    class="d-inline-block"
                    style="width: 200px"
                    @click="updateProperty(item, 'bad_for_dosha_type')"
                >
                </v-checkbox>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text
                  class="d-inline-block"
                  style="overflow: auto; height: 15rem"
              >
                <v-checkbox
                    v-for="item in agni"
                    :key="item.title"
                    :label="item.title"
                    class="d-inline-block"
                    style="width: 200px"
                    @click="updateProperty(item, 'bad_for_agni')"
                >
                </v-checkbox>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text
                  class="d-inline-block"
                  style="overflow: auto; height: 15rem"
              >
                <v-checkbox
                    v-for="item in physiology"
                    :key="item.title"
                    :label="item.title"
                    class="d-inline-block"
                    style="width: 200px"
                    @click="updateProperty(item, 'bad_for_physiology')"
                >
                </v-checkbox>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text
                  class="d-inline-block"
                  style="overflow: auto; height: 15rem"
              >
                <v-checkbox
                    v-for="item in physicalActivity"
                    :key="item.title"
                    :label="item.title"
                    class="d-inline-block"
                    style="width: 200px"
                    @click="updateProperty(item, 'bad_for_physical_activity')"
                >
                </v-checkbox>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text
                  class="d-inline-block"
                  style="overflow: auto; height: 15rem"
              >
                <v-checkbox
                    v-for="item in mindGunas"
                    :key="item.title"
                    :label="item.title"
                    class="d-inline-block"
                    style="width: 200px"
                    @click="updateProperty(item, 'bad_for_mind_gunas')"
                >
                </v-checkbox>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-card-actions>
      <v-btn @click="sendRecipe" class="primary">Send</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "CreatingRecipe",
  data() {
    return {
      // Steps
      stemsAmount: 1,

      //GoodFor / BadFor
      diseas: [],
      doshaType: [],
      agni: [],
      physiology: [],
      physicalActivity: [],
      mindGunas: [],


      // Recipe General Selects
      ayurveda: [],
      regional_cuisine: [],
      meal_timing: [],
      seasons: [],
      type_of_dish: [],
      cuisine_style: [],

      // Recipe tab
      recipePropertyTab: 'disease condition',
      recipePropertyItems: ['disease condition', 'dosha property', 'agni', 'physiology', 'physical activity', 'mind guna'],
      tab: 'general',
      items: ['general', 'ingredients', 'steps', 'good for', 'bad for'],

      // Recipe Ingredients
      addTitle: 'Choose Ingredient to Add',
      tmpIngredient: {type: 'secondary', substitutions: [], unit: '', quantity: '', ingredient: ''},
      ingredients: [],
      usedIngredients: [],
      typeIngredient: '',

      // Dialogs
      dialogAddIngredient: false,
      deleteHover: false,

      rules: {text: (v) => v.length > 0},

      recipe: {
        // General
        nutrional_facts: '',
        title: '',
        description: '',
        preparation_time: '',
        cooking_time: '',
        type_of_dish: [],
        meal_timing: [],
        cuisine_style: [],
        ayurveda: [],
        seasons: [],
        regional_cuisine: [],

        // steps
        cooking_steps: [],

        // ingredients
        ingredients: [],

        // GoodFor
        good_for_disease: [],
        good_for_dosha_type: [],
        good_for_agni: [],
        good_for_physiology: [],
        good_for_physical_activity: [],
        good_for_mind_gunas: [],

        // BadFor
        bad_for_disease: [],
        bad_for_dosha_type: [],
        bad_for_agni: [],
        bad_for_physiology: [],
        bad_for_physical_activity: [],
        bad_for_mind_gunas: [],
      },
      recipe_done: new FormData()
    }
  },
  methods: {
    addProperty(item, label) {
      this.recipe[label] = []
      for (let i of this[label]) {
        for (let j of item) {
          if (j === i.title) {
            this.recipe[label].push(i)
          }
        }
      }
    },
    async sendRecipe() {
      for (let item in this.recipe) {
        if (typeof this.recipe[item] !== "string") {
          this.recipe_done.append(item, JSON.stringify(this.recipe[item]))
        }
        else{
          this.recipe_done.append(item, this.recipe[item])
        }
      }
      await axios.post('http://142.93.161.109:8001/api/admin/recipes', this.recipe_done)
      await this.$router.push('/recipes/page=1')
    },
    pushStep(value, index) {
      this.recipe.cooking_steps[index - 1] = value
    },
    addStep() {
      this.stemsAmount += 1
    },
    getSubtitle(item) {
      let title = ''
      for (let subItem of item.substitutions) {
        for (let i of this.usedIngredients) {
          if (subItem === i.id) {
            title += i.title + ' '
          }
        }
      }
      return title
    },
    getTitle(item) {
      for (let usedItem of this.usedIngredients) {
        if (item.ingredient === usedItem.id) {
          return usedItem.title
        }
      }
    },
    saveIngredient() {
      if (this.typeIngredient === 'secondary') {
        if (this.tmpIngredient.ingredient) {
          this.recipe.ingredients.push(this.tmpIngredient)
        }
      }
      for (let ingredient of this.usedIngredients) {
        if (this.ingredients.includes(ingredient)) {
          this.ingredients.splice(this.ingredients.indexOf(ingredient), 1)
        }
      }
      this.tmpIngredient = {type: 'secondary', substitutions: [], unit: '', quantity: '', ingredient: ''}
      this.dialogAddIngredient = false
      this.typeIngredient = ''
      this.addTitle = 'Choose Ingredient to Add'
    },
    addIngredient(item) {
      if (this.usedIngredients.includes(item)) {
        this.deleteIngredient(item.id)
      } else {
        if (this.typeIngredient !== 'secondary') {
          this.recipe.ingredients.push({
            ingredient: item.id,
            quantity: null,
            type: this.typeIngredient,
            unit: null,
            substitutions: []
          })
          this.usedIngredients.push(item)
        } else {
          this.usedIngredients.push(item)
          if (!this.tmpIngredient.ingredient) {
            this.tmpIngredient.ingredient = item.id
            this.ingredients.splice(this.ingredients.indexOf(item), 1)
            this.addTitle = 'Choose any replacement for ' + this.getTitle({ingredient: item.id})
          } else {
            this.tmpIngredient.substitutions.push(item.id)
          }
        }
      }
    },
    returnToIngredients(id) {
      for (let usedItem of this.usedIngredients) {
        if (id === usedItem.id) {
          this.ingredients.push(usedItem)
          this.usedIngredients.splice(this.usedIngredients.indexOf(usedItem), 1)
        }
      }
    },
    deleteIngredient(id) {
      for (let usedItem of this.recipe.ingredients) {
        if (id === usedItem.ingredient) {
          if (usedItem.substitutions.length !== 0) {
            for (let item of usedItem.substitutions) {
              this.returnToIngredients(item)
            }
          }
          this.recipe.ingredients.splice(this.recipe.ingredients.indexOf(usedItem), 1)
          this.returnToIngredients(id)
        }
      }
    },
    removeImage(){
      this.recipe_done.delete('image')
    },
    imageInput() {
      let image = document.querySelector("#image")
      if (this.recipe_done.has('image')) {
        this.recipe_done.set('image', image.files[0])
      } else {
        this.recipe_done.append('image', image.files[0])
      }
    },
    updateProperty(item, label) {
      let action = 'add'
      let index = null
      for (let i in this.recipe[label]) {
        if (item.title === this.recipe[label][i].title) {
          action = 'remove'
          index = this.recipe[label].indexOf(i)
        }
      }
      if (action === 'add') {
        this.recipe[label].push({id: item.id, title: item.title})

      } else {
        this.recipe[label].splice(index, 1)
      }
    },
    showDialog(choice) {
      this.dialogAddIngredient = true
      this.typeIngredient = choice
    },
    goToNext(item) {
      if (this.items.includes(item)) {
        this.tab = item
        this.current_tab = this.items.indexOf(item)
      } else {
        if (this.items.indexOf(this.tab) !== this.items.length - 1) {
          this.tab = this.items[this.items.indexOf(this.tab) + 1]
          this.current_tab++
        }
      }
    },
  },
  async beforeCreate() {
    // Get all recipes
    const {data} = await axios.get('http://142.93.161.109:8001/api/admin/ingredients?page=1&page_size=20')
    this.ingredients = data.results

    // General lists
    const mealTiming = await axios.get('http://142.93.161.109:8001/api/admin/temp/meal_time')
    this.meal_timing = mealTiming.data.result
    const seasons = await axios.get('http://142.93.161.109:8001/api/admin/temp/season')
    this.seasons = seasons.data.result
    const region = await axios.get('http://142.93.161.109:8001/api/admin/temp/regional_cuisine')
    this.regional_cuisine = region.data.result
    const cuisine = await axios.get('http://142.93.161.109:8001/api/admin/temp/cuisine_style')
    this.cuisine_style = cuisine.data.result
    const type_of_dish = await axios.get('http://142.93.161.109:8001/api/admin/temp/type_of_dish')
    this.type_of_dish = type_of_dish.data.result
    const ayurveda = await axios.get('http://142.93.161.109:8001/api/admin/temp/ayurveda')
    this.ayurveda = ayurveda.data.result

    // GoodFor / BadFor
    const disease = await axios.get('http://142.93.161.109:8001/api/admin/temp/disease')
    this.diseas = disease.data.result
    const dosha_type = await axios.get('http://142.93.161.109:8001/api/admin/temp/dosha_type')
    this.doshaType = dosha_type.data.result
    const agni = await axios.get('http://142.93.161.109:8001/api/admin/temp/agni')
    this.agni = agni.data.result
    const physiology = await axios.get('http://142.93.161.109:8001/api/admin/temp/physiology')
    this.physiology = physiology.data.result
    const physiological_activity = await axios.get('http://142.93.161.109:8001/api/admin/temp/physiological_activity')
    this.physicalActivity = physiological_activity.data.result
    const mind_gunas = await axios.get('http://142.93.161.109:8001/api/admin/temp/mind_gunas')
    this.mindGunas = mind_gunas.data.result

  }
}
</script>

<style scoped>
.v-chip {
  max-height: 24px !important;
}

.v-input__control {
  max-height: 26px !important;
}
</style>