import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import IngredientsPage from "@/views/IngredientsPage";
import CreateIngredient from "@/views/CreateIngredient";
import Collections from "@/views/Collections";
import RecipesPage from "@/views/RecipesPage";
import creatingRecipe from "@/components/CreatingRecipe";
import NewsPage from "@/views/NewsPage";
import CreateANew from "@/components/CreateANew";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/ingredients/page=:page',
    name: 'ingredients',
    component: IngredientsPage
  },
  {
    path: '/new_ingredient',
    name: 'newIngredient',
    component: CreateIngredient
  },
  {
    path: '/collections/:param',
    name: 'collections',
    component: Collections
  },
  {
    path: '/recipes/page=:page',
    name: 'recipes',
    component: RecipesPage
  },
  {
    path: '/new_recipe',
    name: 'new_recipe',
    component: creatingRecipe
  },
  {
    path: '/news/page=:page',
    name: 'news',
    component: NewsPage
  },
  {
    path: '/new_new',
    name: 'new_new',
    component: CreateANew,
    props: true
  }
]

const router = new VueRouter({
  routes,
  linkActiveClass:'activel'
})

export default router
